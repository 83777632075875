<template>
  <nav
    class="d-breadcrumbs"
    :class="{ 'd-breadcrumbs__dark': dark }"
  >
    <ul
      class="d-flex subhead-6 align-center"
      itemscope
      itemtype="http://schema.org/BreadcrumbList"
    >
      <template
        v-for="(crumb, i) in crumbs"
        :key="'crumbs' + i"
      >
        <li
          v-if="i !== crumbs.length - 1"
          itemprop="itemListElement"
          itemscope
          itemtype="http://schema.org/ListItem"
        >
          <nuxt-link
            itemprop="item"
            class="d-breadcrumbs__item d-breadcrumbs__item--non-active"
            :to="crumb.to"
          >
            <span
              v-if="i"
              itemprop="name"
            >{{ crumb.text }}</span>
            <template v-else>
              <span itemprop="name">{{ siteName }}</span>
            </template>
          </nuxt-link>
          <meta
            itemprop="position"
            :content="i + 1"
          >
        </li>
        <li
          v-else
          itemprop="itemListElement"
          itemscope
          itemtype="http://schema.org/ListItem"
        >
          <span
            itemprop="item"
            class="d-breadcrumbs__item d-breadcrumbs__item--active"
          ><span itemprop="name">{{ crumb.text }}</span></span>
          <meta
            itemprop="position"
            :content="i + 1"
          >
        </li>
        <li
          v-if="i !== crumbs.length - 1"
          class="d-breadcrumbs__item--slash"
        >
          &nbsp;/&nbsp;
        </li>
      </template>
    </ul>
  </nav>
</template>

<script lang="ts">
import { useMainStore } from '~/store/main/main.store'

interface Breadcrumb {
  to?: string
  text: string
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Breadcrumb[]>,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const crumbs = computed(() => {
      if (props.items) {
        return props.items
      }
      else return []
    })
    const mainStore = useMainStore()
    const provider = computed(() => mainStore.getDomainConfig)

    const siteName = computed(() =>
      provider.value?.provider?.name
        ? provider.value?.provider?.name
        : 'ДомИнтернет',
    )

    onMounted(() => {
      setTimeout(() => {
        if (document) {
          const parent = document
            .getElementsByClassName('d-breadcrumbs')[0]
            ?.getBoundingClientRect()
          if (parent)
            document
              .getElementsByClassName('d-breadcrumbs')[0]
              .scroll({ left: parent.right, behavior: 'smooth' })
        }
      }, 100)
    })

    return {
      crumbs,
      siteName,
    }
  },
})
</script>

<style scoped lang="scss">
.d-breadcrumbs {
  display: flex;
  overflow: auto;
  height: 30px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 5px;
    padding-bottom: 5px;
    height: unset;
  }

  ul,
  menu {
    width: max-content;
    padding-right: 16px;
    //@media (max-width: getBreakpoint(tablet)) {
    //  line-height: 20px;
    //}
  }

  &__item {
    white-space: nowrap;

    &--active {
      color: color(white);
    }

    &--slash {
      color: color(gray-2);
    }

    &--non-active,
    &--non-active &:deep(svg) {
      color: color(gray-2);
      transition: color 0.24s;

      &:hover,
      &:focus {
        color: color(white);
      }
    }

    @media (max-width: getBreakpoint(tablet)) {
      &--non-active &:deep(svg) {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }

  &__dark {
    .d-breadcrumbs__item {
      &--active {
        color: color(secondary-dark);
      }

      &--slash {
        color: color(gray-dark);
      }

      &--non-active,
      &--non-active &:deep(svg) {
        color: color(gray-dark);

        &:hover,
        &:focus {
          color: color(secondary-dark);
        }
      }
    }
  }
}
</style>
